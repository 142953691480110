.container{
    padding:1.5rem;
    display:flex;
}
.logo{
    display: flex;
    align-items: center;
    flex:1;
    gap:.5rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.logo>span{
    font-weight: 600;
}
.right{
    display: flex;
    gap:2rem;
    align-items: center;
    justify-content: center;
}
.menu{
    display: flex;
    gap:2rem;
    font-weight: 500;
    list-style: none;
}
.menu>li:hover{
    border-bottom: 1px solid #FFA047;
    color:#FFA047;
    cursor: pointer;
}
.search{
    width: 7rem;
    height: 30%;
    outline: none;
    border:none;
    border-radius:5px;
    padding:0.5rem;
}
.cart{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}
.bars{
    display: none;
}
@media screen and (max-width:856px){
    .search{
        display: none;
    }
    .right{
        gap:1rem;
    }

}
@media screen and (max-width:640px) {
    .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background:#fff;
        color:#000;
        border-radius:5px;
        flex-direction: column;
        padding: 1rem;

    }
    .menu{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    .bars{
        display: block;
        cursor: pointer;
    }
    .search{
        display: none;
    }
    
}