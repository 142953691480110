.container{
    padding:0rem 2rem;
    display: grid;
    grid-template-columns:1fr 3fr 1fr;
}
.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}
.wrapper > img{
    width: 30rem;
    position:absolute;
    bottom:0;
}
.blue{
    width: 27rem;
    height: 27rem;
    border-radius:50%;
    z-index: -99;
    position:absolute;
    bottom: 0;
    top:5%;
    background: #F4DC4F;

}
.cart2{
   position:absolute;
   bottom: 25%;
   right:5%;
   display: flex;
    gap:1rem;
    align-items: center;
}
.cart2>svg{
    width: 20px;
    height:20px;
    background-color: #fff;
    padding: 10px;
    border-radius:50%;
    border:6px solid var(--black);
}
.signup{
    display: flex;
    gap:1rem;
    align-items: center;
    background:#fff;
    padding: 7px;
    font-size:0.8rem;
    border-radius: 13px;
    box-shadow:var(--shadow);
}
.signup>:first-child{
    display: block;
    width:6rem;

}
.signup>:last-child{
  border-radius:50%;
  border:1px solid skyblue;
  padding: 5px;
  display: flex;
}
.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1{
    text-transform: uppercase;
    font-size:1.5rem;
    width:min-content;
    display: block;
    font-weight: 600;
}
.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: #000;
}
.text2>:first-child{
    font-weight: 800;
    font-size:2.5rem;
}
.text2>:nth-child(2){
    display: block;
}
.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;

}
.traffic>:first-child{
   font-weight: 800;
   font-size:2.5rem;
}
.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.customers>:first-child{
    font-weight: 800;
    text-align: right;
    font-size:2.5rem;
}
@media screen and (max-width:856px) {
   .text1, .text2>span:nth-child(2){
    font-size: 0.9rem;
    text-align:justify;
   }
   .text2>span:nth-of-type(1),
   .traffic>span:nth-of-type(1),
   .customers>span:nth-of-type(1){
    font-size: 1.5rem;
    text-align:left;
   }
   .blue{
    position: absolute;
    width: 100%;
    height:100%;
   }
   .wrapper>img{
    width: 100%;
    height: 100%;
   }
   .container{
     grid-template-areas: 
     'left center center'
       'right right right';
   }
   .container>:first-child{
     grid-area:left;
     grid-template-rows:none;
     gap:8rem;
   }
   .container>:nth-child(2){
    grid-area:center;

   }
   .container>:nth-child(3){
    grid-area:right;
    display: flex;
    justify-content: space-around;
    margin:2rem 0rem;
    
   }
   .cart2{
    
   }
}
@media screen and (max-width:640px) {
    .blue{
        top: 2.3rem;
        width: 16rem;
        height: 70%;
    }
    .wrapper>img{
        top: 3rem;
        width: 10rem;
        height: 15rem;
    }
   .cart2{
    transform: scale(.7);
    right: -2rem;
    bottom: 0;
   }
    
}
